import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { AppComponentBase } from '@shared/common/app-component-base';
import { SeriesSankeyPointOptionsObject } from 'highcharts';
import ExportingModule from 'highcharts/modules/exporting';
import Sankey from 'highcharts/modules/sankey';
import Organization from 'highcharts/modules/organization';
import Accessibility from 'highcharts/modules/accessibility';
import * as Highcharts from 'highcharts';
import domToImage from 'dom-to-image';
import jsPDF from 'jspdf';
import { timer } from 'rxjs';
import { isEmpty as _isEmpty } from 'lodash';
import { saveAs } from 'file-saver';

Sankey(Highcharts);
Organization(Highcharts);
ExportingModule(Highcharts);
Accessibility(Highcharts);

@Component({
    selector: 'app-mazars-graph',
    styleUrls: ['./mazars-graph.component.css'],
    templateUrl: './mazars-graph.component.html',
})
export class MazarsGraphComponent extends AppComponentBase implements OnChanges, OnInit, AfterViewInit {
    @Input() width;
    @Input() height;
    @Input() chartTitle = '';
    @Input() nodes: Highcharts.SeriesSankeyNodesOptionsObject[] = [];
    @Input() links: SeriesSankeyPointOptionsObject[] = [];
    @Input() onNodeClick = null;
    @Input() formatLabels = null;
    @ViewChild('chart', { static: false }) public chart: ElementRef;
    @Output() onZoomLevelChanged = new EventEmitter<number>();
    @Input() showExport = true;
    public highchartEl;
    public highchartElContainer;

    Highcharts: typeof Highcharts = Highcharts;
    chartRef;
    updateFlag = false;
    chartOptions: Highcharts.Options = {};
    showDragCursor = false;

    ngOnChanges(changes: SimpleChanges): void {
        if (_isEmpty(this.chartOptions)) {
            return;
        }

        if (this.links && this.nodes) {
            this.chartOptions.chart.height = this.getOrgChartHeight();
            this.chartOptions.title.text = this.chartTitle;
            this.chartOptions.series[0]['data'] = [...this.links];
            this.chartOptions.series[0]['nodes'] = [...this.nodes];
            this.updateFlag = true;
        }

        if (this.onNodeClick) {
            this.chartOptions.plotOptions.series.point.events.click = this.onNodeClick;
        }

        if (this.formatLabels) {
            this.chartOptions.series[0]['dataLabels'].nodeFormatter = this.formatLabels;
            this.updateFlag = true;
        }
        timer(500).subscribe(() => {
            this.centerScroll();
        });
    }

    getOrgChartHeight(): number {
        const level = this.nodes?.map((o) => o.level);
        if (level?.length > 0) {
            const height = Math.max(...level) * 120;
            if (height < 700) {
                return 700;
            } else {
                return height;
            }
        } else {
            return 700;
        }
    }

    getOrgChartWidth(): number {
        return this.highchartEl?.offsetWidth || 800;
    }

    ngAfterViewInit(): void {
        this.highchartEl = this.chart.nativeElement.querySelectorAll('.highcharts-container')[0];
        this.highchartElContainer = this.chart.nativeElement.querySelectorAll('.drag-scroll-content')[0];

        this.zoomBack();
    }

    ngOnInit(): void {
        this.chartOptions = {
            chart: {
                height: this.getOrgChartHeight(),
                inverted: true,
                events: {
                    load() {
                        const chart = this as any;
                        const series = chart.series[0];
                        const newWidth = series.options.minNodeLength * Math.max(...series.nodeColumns.map((el) => el.length)) * 2;
                        const currentWidth = chart.containerBox.width;
                        if (newWidth > currentWidth) {
                            chart.update({
                                chart: {
                                    width: newWidth,
                                },
                            });
                        }
                    },
                },
            },
            title: {
                text: this.chartTitle,
            },
            plotOptions: {
                series: {
                    stickyTracking: false,
                    point: {
                        events: {
                            click: this.onNodeClick,
                        },
                    },
                    turboThreshold: 100000
                },
            },
            accessibility: {
                point: {
                    descriptionFormat: '{add index 1}. {toNode.name}' + '{#if (ne toNode.name toNode.id)}, {toNode.id}{/if}, ' + 'reports to {fromNode.id}',
                },
            },
            series: [
                {
                    type: 'organization',
                    name: '',
                    keys: ['from', 'to'],
                    data: this.links,
                    nodes: this.nodes,
                    colorByPoint: false,
                    color: 'white',
                    borderColor: '#0071ce',
                    minNodeLength: 100,
                    nodeWidth: 80,
                    dataLabels: {
                        enabled: true, // Enable dataLabels for all levels
                        inside: true,
                        nodeFormatter: this.formatLabels,
                    } as Highcharts.SeriesOrganizationDataLabelsOptionsObject,
                } as Highcharts.SeriesOptionsType,
            ],
            tooltip: {
                outside: true,
                formatter: function () {
                    return this.point['label'] || this.point['label'] != '' ? this.point['label'] : false;
                },
            } as Highcharts.TooltipOptions,
            exporting: {
                allowHTML: true,
                sourceWidth: this.chartRef?.width,
                sourceHeight: this.chartRef?.height,
                printMaxWidth: this.chartRef?.width,
                enabled: this.showExport,
            },
        };
    }

    chartCallback: Highcharts.ChartCallbackFunction = (chart) => {
        this.chartRef = chart;
    };

    zoomBack() {
        this.setZoom(1);
        this.onZoomLevelChanged.emit(1);
    }

    dragStart() {
        this.showDragCursor = true;
    }

    dragEnd() {
        this.showDragCursor = false;
    }

    downloadAsPdf(fileName: string, format: string, downloadFull: boolean): void {
        this.showMainSpinner();
        if (downloadFull) {
            this.zoomBack();
        }
        setTimeout(() => {
            const el = this.highchartEl;
            let width = el.offsetWidth;
            let height = el.offsetHeight + 40;
            let scale = 2;
            let imageWidth = width * scale;
            let imageHeight = height * scale;
            domToImage
                .toPng(el, {
                    width: imageWidth,
                    height: imageHeight,
                    style: {
                        transform: 'scale(' + scale + ')',
                        transformOrigin: 'top left',
                    },
                })
                .then((result) => {
                    const pdf = new jsPDF({
                        orientation: 'landscape',
                        unit: 'pt',
                        format: format,
                    });
                    const maxWidth = pdf.internal.pageSize.getWidth();
                    const maxHeight = pdf.internal.pageSize.getHeight();

                    const ratio = Math.min(maxWidth / imageWidth, maxHeight / imageHeight);

                    const widthToFit = imageWidth * ratio;
                    const heightToFit = imageHeight * ratio;

                    pdf.addImage(result, 'png', 0, 0, widthToFit, heightToFit);
                    pdf.save(fileName + '.pdf');
                    this.hideMainSpinner();
                })
                .catch((error) => {
                    console.error(error);
                    this.hideMainSpinner();
                });
        }, 500); // needed for downloading full map
    }

    public setZoom(zoom) {
        let transformOrigin = [0, 0];
        let el = this.highchartEl;
        const p = ['webkit', 'moz', 'ms', 'o'],
            s = 'scale(' + zoom + ')',
            oString = transformOrigin[0] * 100 + '% ' + transformOrigin[1] * 100 + '%';

        for (const element of p) {
            el.style[element + 'Transform'] = s;
            el.style[element + 'TransformOrigin'] = oString;
        }

        el.style['transform'] = s;
        el.style['transformOrigin'] = oString;

        this.centerScroll();
    }

    showVal(a) {
        let zoomScale = Number(a) / 10;
        this.setZoom(zoomScale);
    }

    centerScroll() {
        const elementContainer = this.highchartElContainer;
        elementContainer.scrollTo((elementContainer.scrollWidth - elementContainer.offsetWidth) / 2, 0);
    }

    openFullScreen() {
        this.chartRef.fullscreen.open();
    }

    printMap() {
        this.chartRef.print();
    }

    downloadPNG(): void {
        this.showMainSpinner();
        setTimeout(() => {
            const el = this.highchartEl;
            let width = el.offsetWidth;
            let height = el.offsetHeight + 40;
            let scale = 2;
            let imageWidth = width * scale;
            let imageHeight = height * scale;
            domToImage
                .toPng(el, {
                    width: imageWidth,
                    height: imageHeight,
                    style: {
                        transform: 'scale(' + scale + ')',
                        transformOrigin: 'top left',
                    },
                })
                .then(function (blob) {
                    saveAs(blob, 'orgchart.png');
                    this.hideMainSpinner();
                })
                .catch((error) => {
                    console.error(error);
                    this.hideMainSpinner();
                });
        }, 500); // needed for downloading full map
    }

    downloadJpeg() {
        this.showMainSpinner();
        setTimeout(() => {
            const el = this.highchartEl;
            let width = el.offsetWidth;
            let height = el.offsetHeight + 40;
            let scale = 2;
            let imageWidth = width * scale;
            let imageHeight = height * scale;
            domToImage
                .toJpeg(el, {
                    width: imageWidth,
                    height: imageHeight,
                    style: {
                        transform: 'scale(' + scale + ')',
                        transformOrigin: 'top left',
                    },
                })
                .then(function (blob) {
                    saveAs(blob, 'orgchart.jpeg');
                    this.hideMainSpinner();
                })
                .catch((error) => {
                    console.error(error);
                    this.hideMainSpinner();
                });
        }, 500); // needed for downloading full map
    }

    downloadSvg() {
        this.showMainSpinner();
        setTimeout(() => {
            const el = this.highchartEl;
            let width = el.offsetWidth;
            let height = el.offsetHeight + 40;
            let scale = 2;
            let imageWidth = width * scale;
            let imageHeight = height * scale;
            domToImage
                .toSvg(el, {
                    width: imageWidth,
                    height: imageHeight,
                    style: {
                        transform: 'scale(' + scale + ')',
                        transformOrigin: 'top left',
                    },
                })
                .then(function (blob) {
                    saveAs(blob, 'orgchart.svg');
                    this.hideMainSpinner();
                })
                .catch((error) => {
                    console.error(error);
                    this.hideMainSpinner();
                });
        }, 500); // needed for downloading full map
    }
}
