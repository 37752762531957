import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { AgGridAngular } from 'ag-grid-angular';
import {
    ColDef,
    FirstDataRenderedEvent,
    GetDataPath,
    GetRowIdFunc,
    GetRowIdParams,
    GridOptions,
    GridReadyEvent,
    RowDragEndEvent,
    RowSelectedEvent,
    RowValueChangedEvent,
    SelectionChangedEvent,
} from 'ag-grid-community';
import 'ag-grid-enterprise';
import { AppComponentBase } from '@shared/common/app-component-base';
import { CommonGridConfigurationFacade } from '../../state-management/facades/common-grid-configuration.facade';
import { MazarsGridDefinitionInput } from '@app/modules/account-mapping/account-mapping-proxies';
import { IMazarsColumnDefinitionDto, IMazarsColumnDefinitionInput, IMazarsGridDefinitionDto } from '@shared/service-proxies/common-interfaces';
import { cloneDeep as _cloneDeep } from 'lodash-es';

@Component({
    selector: 'mazars-ag-grid',
    templateUrl: './mazars-ag-grid.component.html',
    styleUrls: ['./mazars-ag-grid.component.css'],
    encapsulation: ViewEncapsulation.None,
})
export class MazarsAngularGridComponent extends AppComponentBase implements OnChanges {
    // For accessing the Grid's API
    @ViewChild(AgGridAngular) agGrid!: AgGridAngular;
    @Input() gridConfigurationFacade: CommonGridConfigurationFacade;
    @Input() uid: string;
    @Input() data: any[] = [];
    @Input() isVisible = false;
    @Input() isTreeData = false;
    @Input() showAllColumns = false;
    @Input() columnDefinitions: ColDef[];
    @Input() defaultColumnDefinition: ColDef[];
    @Input() autoGroupColumnDefinition: ColDef[];
    @Input() rowDragManaged: boolean;
    @Input() rowDragMultiRow: boolean;
    @Input() suppressRowDrag = false;
    @Input() rowSelection: 'single' | 'multiple';
    @Input() pinnedBottomRowData: any[];
    @Input() pinnedTopRowData: any[];
    @Input() editType = 'fullRow';
    @Input() noDataText = 'No Rows To Show';
    @Input() headerHeight: number;
    @Input() alwaysShowVerticalScroll?: boolean;
    @Input() sizeColumngToFitOnGridResize: boolean;
    @Input() style: string;
    @Input() autoHeight = true;
    @Input() isResizable = false;
    @Input() groupIncludeFooter: any;
    @Input() styleClass = 'ag-theme-alpine';
    @Input() groupDefaultExpanded = -1;
    @Input() gridOptions: GridOptions[];

    @Output() getAllRecords = new EventEmitter(true);
    @Output() rowValueChanged = new EventEmitter(true);
    @Output() rowDragEnd = new EventEmitter(true);
    @Output() firstDataRendered = new EventEmitter(true);
    @Output() selectionChanged = new EventEmitter(true);
    @Output() rowSelected = new EventEmitter(true);
    @Output() columnResized = new EventEmitter(true);

    columnDefinitionDto: IMazarsColumnDefinitionDto[];
    minimumColWidth = 6;

    @Input() getRowId: GetRowIdFunc = (params: GetRowIdParams) => params.data.id;

    ngOnChanges(changes: SimpleChanges): void {
        const showAllColumnsChanges = changes['showAllColumns'];
        if (showAllColumnsChanges && !showAllColumnsChanges.firstChange && showAllColumnsChanges.previousValue !== showAllColumnsChanges.currentValue) {
            this.onColumnConfigChanged();
        }
    }

    public getDataPath: GetDataPath = (data: any) => data.path;

    onColumnResized($event: any) {
        if ($event.finished === true && $event?.source === 'uiColumnResized') {
            const columns = $event.columns;
            columns.forEach((column) => {
                const col = this.columnDefinitionDto.find((c) => c.field === column.colId);
                if (col) {
                    col.width = this.getRelativeSize(column.actualWidth);
                }
            });
            let input = {
                tableId: this.uid,
                configuration: this.dataToConfig(this.columnDefinitionDto),
            } as MazarsGridDefinitionInput;
            this.gridConfigurationFacade.setGridColumnSettings(input);
        }
    }

    onColumnConfigChanged() {
        if (this.columnDefinitionDto && this.columnDefinitionDto.length > 0) {
            this.columnDefinitionDto.forEach((definition, i) => {
                let columnDef = this.columnDefinitions.find((cd) => cd.colId === definition.field);
                if (columnDef) {
                    if (!definition.canBeChanged) {
                        this.agGrid.columnApi.setColumnVisible(columnDef.colId, false); //In that case we hide it
                    } else {
                        this.agGrid.columnApi.setColumnVisible(columnDef.colId, definition.visible);
                    }
                    if (this.showAllColumns && columnDef.editable) {
                        this.agGrid.columnApi.setColumnVisible(columnDef.colId, true);
                    } else if (this.showAllColumns && !columnDef.editable) {
                        this.agGrid.columnApi.setColumnVisible(columnDef.colId, false);
                    }
                }
            });

            const visibleCols = this.columnDefinitionDto?.filter((d) => d.visible === true);
            if (visibleCols.length > 0) {
                this.columnDefinitions.forEach((colDefinition) => {
                    colDefinition.width = this.columnWidth(this.columnDefinitionDto.find((d) => d.field === colDefinition.colId)?.width);
                });
                this.agGrid.api.setColumnDefs(this.columnDefinitions);
            }

            let keys = this.columnDefinitionDto.map((def) => def.field);
            this.agGrid.columnApi.moveColumns(keys, 1); // set columns order except first one (Account Name)

            if (this.isVisible) {
                this.agGrid.api.sizeColumnsToFit({
                    defaultMinWidth: 1,
                });
            }
        }
    }

    dataToConfig(definitions: any): IMazarsColumnDefinitionInput[] {
        let input: IMazarsColumnDefinitionInput[] = definitions.map(
            (definition) =>
                ({
                    field: definition?.field,
                    isDefault: definition?.isDefault,
                    visible: definition?.visible,
                    canBeChanged: definition?.canBeChanged,
                    localizationKey: definition?.localizationKey,
                    localizationSource: definition?.localizationSource,
                    width: definition?.width,
                } as IMazarsColumnDefinitionInput)
        );
        return input;
    }

    columnWidth(percentage) {
        const table = document.getElementById(this.uid);
        let width = (table?.getBoundingClientRect().width * percentage) / 100; // Pixel calculation
        return Math.round(width);
    }

    getRelativeSize(clientWidth: number): number {
        const table = document.getElementById(this.uid);
        return Math.round((clientWidth / table?.getBoundingClientRect().width) * 100);
    }

    onGridReady(params: GridReadyEvent) {
        if (this.isVisible) {
            this.agGrid.api.sizeColumnsToFit();
        }
        this.getAllRecords.emit(params);

        if (this.isResizable) {
            this.subscriptions.push(
                this.gridConfigurationFacade.gridColumnSettings$.subscribe({
                    next: (definition: IMazarsGridDefinitionDto) => {
                        if (definition?.configuration) {
                            if (JSON.stringify(this.columnDefinitionDto) !== JSON.stringify(definition.configuration)) {
                                this.columnDefinitionDto = _cloneDeep(definition.configuration);
                                this.onColumnConfigChanged();
                            }
                        }
                    },
                })
            );

            this.subscriptions.push(
                this.gridConfigurationFacade.gridColumnSettingsConfigChanged$.subscribe({
                    next: (config: any) => {
                        if (config?.data?.configuration) {
                            this.columnDefinitionDto = _cloneDeep(config.data.configuration);
                            this.onColumnConfigChanged();
                        }
                    },
                })
            );
        }
    }

    onGridSizeChanged() {
        if (this.sizeColumngToFitOnGridResize && this.isVisible) {
            this.agGrid.api.sizeColumnsToFit();
        }
    }

    onRowValueChanged(event: RowValueChangedEvent) {
        this.rowValueChanged.emit(event);
    }

    onRowDragEnd(event: RowDragEndEvent) {
        this.rowDragEnd.emit(event);
    }

    onFirstDataRendered(event: FirstDataRenderedEvent) {
        this.firstDataRendered.emit(event);
    }

    onSelectionChanged(event: SelectionChangedEvent) {
        this.selectionChanged.emit(event);
    }

    onRowSelected(event: RowSelectedEvent) {
        this.rowSelected.emit(event);
    }

    onDestroy(): void {
        // to be implemented if need it
    }

    onWindowResize() {
        if (this.isVisible) {
            this.agGrid.api.sizeColumnsToFit();
        }
    }
}
