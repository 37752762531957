<ul class="mz-nav-tabs mb-5" role="tablist" [attr.data-testid]="uid">
    <ng-container *ngFor="let item of items">
        <li *ngIf="!item.hidden">
            <a
                [attr.data-testid]="uid + '-tab-' + item?.id"
                style="font-size: 100%"
                routerLinkActive="active"
                [routerLinkActiveOptions]="item.ignoreQueryParams ? { paths: 'exact', queryParams: 'ignored', matrixParams: 'ignored' } : { exact: true }"
                [routerLink]="[item.routerLink]"
                [class.disabled]="item.disabled"
            >
                <i aria-hidden="true" *ngIf="item.icon" class="{{ item.icon }} mr-2" style="color: inherit"></i>
                {{ item.labelAsHtml ? '' : item.label }}
                <span *ngIf="item.labelAsHtml" [innerHtml]="item.label | safe: securityContext.HTML"></span>
            </a>
        </li>
    </ng-container>
    <li class="last-child"></li>
    <div class="d-flex ml-auto">
        <ng-container *ngFor="let item of iconTabs">
            <li *ngIf="!item.hidden" class="mz-nav-tab-right">
                <a
                    [attr.data-testid]="uid + '-tab-icon-' + item?.id"
                    routerLinkActive="active"
                    [routerLinkActiveOptions]="item.ignoreQueryParams ? { paths: 'exact', queryParams: 'ignored', matrixParams: 'ignored' } : { exact: true }"
                    [routerLink]="[item.routerLink]"
                    [class.disabled]="item.disabled"
                    class="pr-3 pl-3 position-relative"
                >
                    <i
                        [mazarsTooltip]="item.tooltip"
                        [tooltipPosition]="'left'"
                        aria-hidden="true"
                        *ngIf="item.icon"
                        class="{{ item.icon }} p-0 m-0"
                        style="color: inherit; font-size: 150%"
                    ></i>
                    <span
                        class="position-absolute translate-middle badge badge-circle badge-error"
                        style="font-size: 90%; width: 18px; height: 18px; top: 15px; left: 70%"
                        *ngIf="item?.showIconCounter && item?.iconCounter > 0"
                    >
                        {{ item.iconCounter }}
                    </span>
                </a>
            </li>
        </ng-container>
    </div>
</ul>
