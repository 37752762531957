<div class="d-flex mb-8 align-items-center">
    <div class="flex-grow-1">
        <app-mazars-steps [uid]="uid + '_steps'" [model]="steps" [activeIndex]="activeIndex"></app-mazars-steps>
    </div>
    <div *ngIf="mainAction" class="ml-5">
        <p-splitButton
            *ngIf="subActions.length>0"
            appendTo="body"
            class="bg-dark-blue"
            [disabled]="mainAction.disabled"
            [label]="mainAction.label"
            [model]="subActions"
            (onClick)="execute(mainAction)"
            [attr.data-testid]="uid + '_split-btn'"
        ></p-splitButton>
        <button
            *ngIf="subActions.length==0"
            class="btn bg-dark-blue"
            [disabled]="mainAction.disabled"
            (click)="execute(mainAction)"
            [attr.data-testid]="uid + '_process-btn'"
        >
            {{ mainAction.label }}
        </button>
    </div>
</div>