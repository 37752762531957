import { Component, Input, OnInit } from '@angular/core';
import { AppComponent } from '@app/app.component';

@Component({
    selector: 'app-mazars-skeleton',
    templateUrl: './mazars-skeleton.component.html',
})
export class MazarsSkeletonComponent extends AppComponent implements OnInit {
    @Input() uid!: string;
    @Input() shape: 'input' | 'textarea' | 'checkbox' | 'text';
    @Input() pHeight = '3rem';
    @Input() pWidth = '100%';
    pShape = 'rectangle';
    pStyle: any = null;
    pStyleClass: string = null;
    pBorderRadius: string = null;
    pSize: string = null;

    ngOnInit(): void {
        if (this.shape === 'textarea') {
            this.pHeight = '9rem';
        } else if (this.shape === 'checkbox') {
            this.pHeight = '1.25rem';
            this.pWidth = '1.25rem';
            this.pStyleClass = 'p-checkbox';
        } else if (this.shape === 'text') {
            this.pHeight = '1.25rem';
        }
    }
}
