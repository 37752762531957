<p-avatarGroup *ngIf="userDetail" [attr.data-testid]="uid">
    <ng-template #popTemplate>
        <div class="d-flex">
            <p-avatar size="xlarge" shape="circle" [image]="'data:image/jpeg;base64,' + userDetail.profilePicture"></p-avatar>
            <div class="ms-3">
                <div class="mb-3">{{ userDetail.fullName }}</div>
                <div>{{ userDetail.email }}</div>
            </div>
        </div>
    </ng-template>
    <p-avatar
        [popover]="popTemplate"
        triggers="mouseenter:mouseleave"
        shape="circle"
        [size]="size"
        [image]="'data:image/jpeg;base64,' + userDetail.profilePicture"
    ></p-avatar>
</p-avatarGroup>
