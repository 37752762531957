<div #chart [class]="showDragCursor ? 'grabbable' : 'cursor-default' + ' ' + class">
    <drag-scroll style="width: 100%" (dragStart)="dragStart()" (dragEnd)="dragEnd()">
        <highcharts-chart
            [Highcharts]="Highcharts"
            [options]="chartOptions"
            style="width: 100%; display: block"
            [callbackFunction]="chartCallback"
            [(update)]="updateFlag"
        ></highcharts-chart>
    </drag-scroll>
</div>
