import { Injectable, Injector } from '@angular/core';
import { Observable, Subject, throwError, timer } from 'rxjs';
import { HttpRequest, HttpResponse, HttpHandler, HttpEvent,  } from '@angular/common/http';
import { filter, finalize, share, switchMap, tap } from 'rxjs/operators';
import { AbpHttpConfigurationService, AbpHttpInterceptor, LocalizationService, MessageService } from 'abp-ng2-module';
import { AppConsts } from '@shared/AppConsts';
import { NavigationError, Router, RoutesRecognized } from '@angular/router';
declare const abp: any;

@Injectable({ providedIn: 'root' })
export class MazarsAbpHttpInterceptor extends AbpHttpInterceptor {
    protected configuration: AbpHttpConfigurationService;
    private _messageService: MessageService;
    private _router: Router;
    private _als: LocalizationService;

    private readonly maxParallelRequestCount = 10;
    private runningRequestsCount = 0;
    private pendingRequestsQueue: { observable: Observable<HttpEvent<any>>, subject: Subject<HttpEvent<any>>, isActive: boolean, onCancellation: () => void}[] = [];

    constructor(configuration: AbpHttpConfigurationService, _injector: Injector, _messageService: MessageService) {
        super(configuration, _injector);
        this.configuration = configuration;
        this._messageService = _messageService;
        this._router = _injector.get(Router);
        this._als = _injector.get(LocalizationService);
    }

    protected normalizeRequestHeaders(request: HttpRequest<any>): HttpRequest<any> {
        if (!request.url.includes(AppConsts.remoteServiceBaseUrl)) {
            return request;
        }

        let modifiedHeaders = request.headers.set('Pragma', 'no-cache').set('Cache-Control', 'no-cache').set('Expires', 'Sat, 01 Jan 2000 00:00:00 GMT');
        modifiedHeaders = this.addXRequestedWithHeader(modifiedHeaders);
        modifiedHeaders = this.addAuthorizationHeaders(modifiedHeaders);
        modifiedHeaders = this.addAspNetCoreCultureHeader(modifiedHeaders);
        modifiedHeaders = this.addAcceptLanguageHeader(modifiedHeaders);
        modifiedHeaders = this.addTenantIdHeader(modifiedHeaders);

        return request.clone({
            headers: modifiedHeaders,
        });
    }

    override intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // If request is not towards our API backend, don't use queuing mechanism.
        if (!request.url.startsWith(AppConsts.remoteServiceBaseUrl)) {
            return super.intercept(request, next);
        }
        
        return this.enqueue(super.intercept(request, next));
    }

    private enqueue(innerObservable: Observable<HttpEvent<any>>): Observable<HttpEvent<any>> {
        // TODO: if returned observable is never subscribed from outside, this implementation will always keep it in runningRequestsCount...

        // Functionality to trigger dequeuing exactly once regardless wehter success, error, unsubscription, ...
        let isRemovedFromRunning = false;
        const handleFinish = () => {
            if (isRemovedFromRunning) return;
            // execution finished (first time ;-) )
            this.runningRequestsCount --;
            isRemovedFromRunning = true;
            this.triggerExecutionFromQueue();
        }

        // Create actual observable for the request that takes care of dequeue triggering
        const observable = innerObservable.pipe(
            tap(
                {
                next: (event) => {
                    if (event instanceof HttpResponse) {
                        handleFinish();
                    }
                },
                error: (_) => handleFinish(),
                complete: () => handleFinish(),
            })
        );

        // If max parallel executions are not reached, directly execute
        if (this.runningRequestsCount < this.maxParallelRequestCount) {
            // directly execute without adding to queue
            this.runningRequestsCount ++;
            return observable;
        }

        // Create a proxy observable that is passed back to the caller
        const subject = new Subject<HttpEvent<any>>();
        const pending = {
            observable: observable,
            subject: subject,
            isActive: true,
            onCancellation: () => {},
        };

        // add to queue and start
        this.pendingRequestsQueue.push(pending);
        setTimeout(() => this.triggerExecutionFromQueue(), 0);

        return subject.pipe(
            finalize(() => {
                /* last subscriber unsubscribed */
                pending.isActive = false;
                pending.onCancellation();
                handleFinish();
            }),
            share()
        );
    }

    private triggerExecutionFromQueue(): void {
        if (this.pendingRequestsQueue.length == 0 || this.runningRequestsCount >= this.maxParallelRequestCount) {
            return;
        }

        const pending = this.pendingRequestsQueue.shift();
        if (!pending.isActive) {
            this.triggerExecutionFromQueue();
            return;
        }

        // take from queue and execute
        this.runningRequestsCount ++;

        const subscription = pending.observable.subscribe({
            next: (value) => pending.subject.next(value),
            error: (error) => pending.subject.error(error),
            complete: () => pending.subject.complete(),
        });

        pending.onCancellation = () => subscription.unsubscribe();
    }

    protected handleErrorResponse(error: any): Observable<never> {
        if (!(error.error instanceof Blob)) {
            return throwError(() => error);
        }
        if (error?.status === 504) {
            this._messageService.error(error.statusText);
            return throwError(() => error);
        }
        return this.configuration.blobToText(error.error).pipe(
            switchMap((json) => {
                const errorBody = !!json ? JSON.parse(json) : {};
                const errorResponse = new HttpResponse({
                    headers: error.headers,
                    status: error.status,
                    body: errorBody,
                });

                let ajaxResponse = this.configuration.getAbpAjaxResponseOrNull(errorResponse);

                if (ajaxResponse != null) {
                    if (ajaxResponse.error?.code !== 400) {
                        if (ajaxResponse.error?.message === 'Content_Not_Found') {
                            this._router.events.pipe(filter((evt: any) => evt instanceof NavigationError)).subscribe((events: RoutesRecognized[]) => {
                                ajaxResponse.error.message = this._als.localize(ajaxResponse.error.message, AppConsts.localization.defaultLocalizationSourceName);
                                this.configuration.handleAbpResponse(errorResponse, ajaxResponse);
                                timer(1000).subscribe((_) => {
                                    history.back();
                                });
                            });
                        } else {
                            this.configuration.handleAbpResponse(errorResponse, ajaxResponse);
                        }
                    }
                } else {
                    this.configuration.handleNonAbpErrorResponse(errorResponse);
                }

                return throwError(() => error);
            })
        );
    }
}
