<div class="footer py-1" id="kt_footer">
    <div [class]="containerClass">
        <span class="text-muted mr-2 text-nowrap small">
            Digital Tax Portal
            <!--<span
                *ngIf="appSession.tenant && appSession.tenant.edition && appSession.tenant.edition.displayName">
                {{appSession.tenant.edition.displayName}}</span> | API: v{{appSession.application.version}} | Client: v{{webAppGuiVersion}}-->
            | {{ releaseDate }}
            {{ appSession?.tenant?.tenancyName ? '| ' + appSession?.tenant?.tenancyName : '' }}
            |
            <a routerLink="/details/release-notes">{{ l('ReleaseNotes') }}</a>
            |
            <a routerLink="/details/user-manuals">{{ l('UserManual') }}</a>
            |
            <a routerLink="/account/information/imprint">{{ l('Imprint') }}</a>
            |
            <a routerLink="/account/information/privacy-policy">{{ l('PrivacyPolicy') }}</a>
        </span>
    </div>
</div>
