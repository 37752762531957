import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { MenuItem, MenuItemCommandEvent } from 'primeng/api';

@Component({
    selector: 'app-mazars-process',
    templateUrl: './mazars-process.component.html',
})
export class MazarsProcessComponent implements OnChanges {
    @Input({ required: true }) uid: string;
    @Input() steps: MenuItem[] = [];
    @Input() activeIndex: number = 0;
    @Input() actions: MenuItem[] = [];

    mainAction: MenuItem | undefined;
    subActions: MenuItem[];

    ngOnChanges(changes: SimpleChanges): void {
        const actions = this.actions.filter(a => a.visible);
        this.mainAction = actions.length > 0 ? actions[0] : undefined;
        this.subActions = actions.splice(1);
    }
    
    execute(menuItem: MenuItem): void {
        if (menuItem.command) menuItem.command(<MenuItemCommandEvent>{
            item: menuItem,
        });
    }
}
